import React from 'react';
import './App.css';
import Weather from "./Components/Weather";

function App() {
  return (
    <div className="App" style={{ minWidth: "360px" }}>
      <header className="flexy">
        <h1 style={{ fontSize: "50pt"}}>
          Sweater Weather
        </h1>
      </header>
      <Weather />
      <footer className="flexy" style={{ marginTop: "50px" }}>
        &copy;&nbsp;
        <a href="http://www.cooperhart.xyz/" style={{ color: "white", textDecoration: "none" }} >Cooper Hart</a>
        &nbsp;- {(new Date()).getFullYear()}
      </footer>
    </div>
  );
}

export default App;
