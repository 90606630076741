import React, { Component } from 'react';
import weatherAPI from "openweather-apis"

class Weather extends Component {

  state = {
    loading: false,
    failed: false,
    location: "",
    current: null,
    started: false,
  };

  getWeather = async () => {
    const { location } = this.state;
    if (!location) return;
    this.setState({ loading: true, started: false });
    weatherAPI.setAPPID("7c1c764d935221a3fec5121f1f42dae4");
    weatherAPI.setCity(location);
    weatherAPI.setUnits('metric');
    weatherAPI.getAllWeather((err, current) => {
      if (err || Math.floor(current.cod/100) !== 2) {
        console.error(err);
        this.setState({ failed: true, loading: false });
        return;
      }
      this.setState({ current: current, loading: false });
    });
    // weatherAPI.getWeatherForecastForDays(7, function(err, obj){
    //   console.log(obj);
    // });
  };

  getNumSweaters = () => {
    const { current } = this.state;
    const temp = current.main.feels_like;
    const rangedTemp = Math.min(20, Math.max(-10, temp));
    const invSweaters = (rangedTemp + 10) / 30 * 5;
    return Math.round((5 - invSweaters)*10)/10;
  };

  inputMarkup = () => {
    const { location } = this.state;
    return (
      <React.Fragment>
        <div>
          <br/>
          <input
            type="text"
            value={location}
            placeholder="Your City"
            onChange={(e, d) => { this.setState({ location: e.target.value }); }}
            style={{
              background: "ghostwhite",
              borderRadius: "9px",
              border: "6px solid slateblue",
              padding: "1em",
              fontSize: "20pt",
              color: "darkslategrey",
              width: "400px",
              textAlign: "center",
              marginTop: "2em",
              marginBottom: "1em",
              maxWidth: "90vw",
              boxSizing: "border-box",
            }}
            onKeyDown={(e) => {if (e.key === 'Enter') { this.getWeather(); }}}
          />
        </div>
        <div>
          <button
            style={{ marginTop: "1em" }}
            className="button"
            onClick={this.getWeather}
          >
            Get Sweaters
          </button>
        </div>
      </React.Fragment>
    )
  };

  loadingMarkup = () => {
    return (
      <div style={{ fontSize: "20pt", marginTop: "2em" }}>
        Loading
      </div>
    )
  };

  failedMarkup = () => {
    const { location } = this.state;
    return (
      <div className="flexy" style={{ flexDirection: "column" }}>
        <h2>Whoopsie..</h2>
        <h2>😢</h2>
        Unable to find any sweater weather for <span style={{ textTransform: "capitalize" }}>{location}</span>
        <button
          className="button"
          onClick={() => {
            this.setState({
              loading: false,
              failed: false,
              current: null,
            })
          }}
          style={{ marginTop: "1em" }}
        >
          Try Again
        </button>
      </div>
    )
  };

  weatherMarkup = () => {
    const { current, location, started } = this.state;
    const numSweaters = this.getNumSweaters();
    const left = numSweaters/5*100;
    if (!started) {
      setTimeout(() => {
        this.setState({ started: true });
      }, 150);
    }
    return (
      <div
        className="flexy"
        style={{
          minWidth: "360px",
          flexDirection: "column",
          borderRadius: "25px",
          border: "4px solid slateblue",
          background: "#5a528e",
          paddingTop: "3em",
          paddingBottom: "3em",
          paddingRight: "min(3em, 3vw)",
          paddingLeft: "min(3em, 3vw)",
          width: "1031px",
          maxWidth: "90vw",
          margin: "1em 0em",
          boxSizing: "border-box",
        }}
      >
        <h2>Sweater Weather for <span style={{ textTransform: "capitalize" }}>{location} ({current.sys.country})</span></h2>
        <div
          style={{
            width: "100%",
            height: "200px",
            maxHeight: "calc(90vw / 5)",
            maskSize: "100% auto",
            mozMaskSize: "100% auto",
            webkitMaskSize: "100% auto",
            maskImage: "url(sweaters.png)",
            webkitMaskImage: "url(sweaters.png)",
            mozMaskImage: "url(sweaters.png)",
            maskRepeat: "no-repeat",
            mozMaskRepeat: "no-repeat",
            webkitMaskRepeat: "no-repeat",
            background: "#5a528e",
            fontSize: "100pt",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              background: "slategrey",
              position: "relative",
              left: "0px",
              top: "0px",
              float: "right",
              zIndex: -9,
            }}
          />
          <div
            style={{
              height: "100%",
              background: "ghostwhite",
              width: started ? left + "%" : "0px",
              transition: "width 3s",
            }}
          />
        </div>
        <div className="flexy" >
          <div style={{ fontSize: "min(15vw, 60pt)", fontWeight: "bold"}}>
            {numSweaters}/5
          </div>
          <div style={{ fontSize: "min(9vw, 30pt)"}}>
            &nbsp;Sweaters
          </div>
        </div>
        <button
          className="button"
          onClick={() => {
            this.setState({
              loading: false,
              failed: false,
              current: null,
              location: "",
            })
          }}
          style={{ marginTop: "1em" }}
        >
          Try Somewhere Else
        </button>
      </div>
    )
  };

  render() {
    const { loading, failed, current } = this.state;
    return (
      <main className="flexy" style={{ flexDirection: "column" }}>
        {!current && !failed && (
          <img
            width="100"
            src="sweater.png"
            className={loading && "spinning"}
          />
        )}
        {!loading && !current && !failed && this.inputMarkup()}
        {loading && this.loadingMarkup()}
        {current && !failed && this.weatherMarkup()}
        {failed && this.failedMarkup()}
      </main>
    )
  }

}

export default  Weather;
